import dynamic from 'next/dynamic';

const GenericGridAuthorable = dynamic(() =>
  import('templates/GenericGridAuthorable').then((module) => module.GenericGridAuthorableContainer),
);

const ContactCardContainer = dynamic(() =>
  import('templates/ContactCardContainer').then((module) => module.ContactCardContainer),
);

const EvalancheFormComponent = dynamic(() =>
  import('templates/EvalancheForm').then((module) => module.EvalancheForm),
);

const ExampleComponent = dynamic(() =>
  import('templates/ExampleComponent').then((module) => module.ExampleComponent),
);

const Form = dynamic(() =>
  import('templates/FormContainer').then((module) => module.FormContainer),
);

const GenericAccordion = dynamic(() =>
  import('templates/GenericAccordion').then((module) => module.GenericAccordion),
);

const GenericFollowUs = dynamic(() =>
  import('templates/SocialMedia').then((module) => module.GenericFollowUs),
);

const Link = dynamic(() => import('templates/Link').then((module) => module.Link));

const HeroComponent = dynamic(() => import('templates/Hero').then((module) => module.Hero));

const HeroCarouselComponent = dynamic(() =>
  import('templates/SliderCarousel').then((module) => module.SliderCarousel),
);

const IntroText = dynamic(() => import('templates/IntroText').then((module) => module.IntroText));

const LongText = dynamic(() => import('templates/LongText').then((module) => module.LongText));

const Tab = dynamic(() => import('templates/TabContent').then((module) => module.TabsContainer));

const GenericDownloadComponent = dynamic(() =>
  import('templates/GenericDownload').then((module) => module.GenericDownload),
);

const Iframe = dynamic(() => import('templates/IFrameComponent').then((module) => module.Iframe));

const GenericSocialShare = dynamic(() =>
  import('templates/GenericSocialShare').then((module) => module.GenericSocialShare),
);

const AnchorNavigationList = dynamic(() =>
  import('templates/AnchorNavigation').then((module) => module.AnchorNavigation),
);

const Spacer = dynamic(() => import('templates/Spacer').then((module) => module.Spacer));

const AuthorableContentCard = dynamic(() =>
  import('templates/AuthorableContentCard').then((module) => module.AuthorableContentCardContainer),
);

const CarouselComponent = dynamic(() =>
  import('templates/ContentSlider').then((module) => module.ContentSlider),
);

const FeaturedContent = dynamic(() =>
  import('templates/FeaturedContent').then((module) => module.FeaturedContent),
);

const AudioComponent = dynamic(() =>
  import('templates/AudioComponent').then((module) => module.AudioComponent),
);

const Banner1 = dynamic(() => import('templates/Banner1').then((module) => module.Banner1));

const Banner2 = dynamic(() => import('templates/Banner2').then((module) => module.Banner2));

const Banner2CarouselComponent = dynamic(() =>
  import('templates/Banner2Slider').then((module) => module.Banner2Slider),
);

const GlobalSearch = dynamic(() =>
  import('templates/GlobalSearch').then((module) => module.GlobalSearch),
);

const ProductBanner = dynamic(() =>
  import('templates/ProductBanner').then((module) => module.ProductBanner),
);

const GridColumnComponent = dynamic(() =>
  import('templates/GridColumnComponent').then((module) => module.GridColumnComponent),
);

const ImageCopy = dynamic(() => import('templates/ImageCopy').then((module) => module.ImageCopy));

const GenericLine = dynamic(() =>
  import('templates/GenericLine').then((module) => module.GenericLine),
);

const GenericHtml = dynamic(() =>
  import('templates/GenericHTML').then((module) => module.GenericHTML),
);

const GenericQualifio = dynamic(() =>
  import('templates/GenericQualifio').then((module) => module.GenericQualifio),
);

const GenericPromotionsOverview = dynamic(() =>
  import('templates/GenericPromotionsOverview').then((module) => module.GenericPromotionsOverview),
);

const Teaser = dynamic(() => import('templates/TeaserLarge').then((module) => module.TeaserLarge));

const TeaserSmall = dynamic(() =>
  import('templates/TeaserSmall').then((module) => module.TeaserSmall),
);

const GenericAccordionTopRated = dynamic(() =>
  import('templates/GenericAccordionTopRated').then((module) => module.GenericAccordionTopRated),
);

const DynamicCardGrid = dynamic(() =>
  import('templates/DynamicCardGrid').then((module) => module.DynamicCardGrid),
);

const GenericCardCarouseComponent = dynamic(() =>
  import('templates/GenericCardCarousel').then((module) => module.GenericCardCarousel),
);

const MultiStepPromotionForm = dynamic(() =>
  import('templates/MultiStepPromotionForm').then(
    (module) => module.MultiStepPromotionFormTemplate,
  ),
);

const templates = {
  AnchorNavigationList,
  AudioComponent,
  AuthorableContentCard,
  Banner1,
  Banner2,
  Banner2CarouselComponent,
  CarouselComponent,
  ContactCardContainer,
  EvalancheFormComponent,
  ExampleComponent,
  FeaturedContent,
  GenericGridAuthorable,
  Form,
  GenericAccordion,
  GenericDownloadComponent,
  GenericFollowUs,
  GenericHtml,
  GenericQualifio,
  GenericPromotionsOverview,
  GenericLine,
  GlobalSearch,
  // TODO 2199 remove the following line when we're no longer using GridColumnComponent in cms
  GridColumnComponent,
  GridColumn: GridColumnComponent,
  HeroCarouselComponent,
  HeroComponent,
  Iframe,
  ImageCopy,
  IntroText,
  Link,
  LongText,
  ProductBanner,
  Tab,
  Teaser,
  TeaserSmall,
  SubTab: Tab,
  Spacer,
  GenericSocialShare,
  DynamicCardGrid,
  GenericAccordionTopRated,
  GenericCardCarouseComponent,
  MultiStepPromotionForm,
};

export default templates;
